<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
      <h4 class="card-title">{{ $t('specialTaskForce.district_special_task_force_activities') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="division_id"
            >
              <template v-slot:label>
                {{ $t('globalTrans.division') }}
              </template>
              <b-form-select
                plain
                v-model="search.division_id"
                :options="divisionList"
                id="divistion_id"
                :disabled="listUserDisable()"
              >
              <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="district_id"
            >
              <template v-slot:label>
                {{ $t('globalTrans.district') }}
              </template>
            <b-form-select
              plain
              v-model="search.district_id"
              :options="districtList"
              id="district_id"
              :disabled="listUserDisable()"
            >
            <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
            </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="date"
            >
              <template v-slot:label>
                {{ $t('globalTrans.from_date') }}
              </template>
              <date-picker
                id="start_date"
                class="form-control"
                v-model="search.start_date"
                :placeholder="$t('globalTrans.select')"
              >
              </date-picker>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="date"
            >
              <template v-slot:label>
                {{ $t('globalTrans.to_date') }}
              </template>
              <date-picker
                id="end_date"
                class="form-control"
                v-model="search.end_date"
                :placeholder="$t('globalTrans.select')"
              >
              </date-picker>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" style="font-size: 14px;" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
      <h4 class="card-title">{{ $t('specialTaskForce.district_special_task_force_activities') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(price_collection_date)="data">
                    {{ data.item.price_collection_date | dateFormat }}
                  </template>
                  <template v-slot:cell(market_directory_ids)="data">
                    {{ getMarketDirectoryList(data.item.market_directory_ids) }}
                  </template>
                  <template v-slot:cell(number_of_agents)="data">
                    {{ $n(data.item.number_of_agents, { useGrouping: false }) }}
                  </template>
                  <template v-slot:cell(inspection_date)="data">
                    {{ data.item.inspection_date| dateFormat }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-primary" v-if="data.item.status == 1">{{$t('globalTrans.draft')}}</span>
                    <span class="badge badge-info" v-else-if="data.item.status == 2">{{$t('dealer.submits')}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button v-b-modal.modal-form variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)" class="action-btn edit" :title="$t('globalTrans.edit')" v-if="data.item.status === 1"><i class="ri-pencil-fill"></i></b-button>
                    <!-- <b-button class="action-btn btn-success" v-if="data.item.status == 1" @click="remove(data.item)" :title="$t('globalTrans.submit')" v-b-modal.details size="sm"><i class="ri-arrow-right-circle-fill"></i></b-button> -->
                    <b-button class="btn action-btn status mr-1 btn-sm" :title="$t('globalTrans.details')" v-b-modal.details variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                    <b-button v-if="$store.state.Auth.activeRoleId === 1" :variant="data.item.status === 2 ? ' btn-danger' : ' btn-success'" size="sm" class="action-btn status" :title="data.item.status === 2 ? $t('globalTrans.inactive') : $t('globalTrans.active')" @click="remove(data.item)">
                      <i :class="data.item.status === 2  ? 'ri-toggle-line' : 'ri-toggle-fill'"></i>
                    </b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal id="details" size="lg" :title="$t('specialTaskForce.district_special_task_force_activities') + ' ' + $t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="editItemId" :key="editItemId" :data="{}" :report="0"/>
    </b-modal>
  </div>
</template>
<script>
import Form from './Form.vue'
import Details from './Details.vue'
import RestApi, { bazarMonitoringServiceBaseUrl } from '@/config/api_config'
import { specialTaskForceDistrictList, specialTaskForceDistrictToggle } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form,
    Details
  },
  data () {
    return {
      sortBy: '',
      search: {
        division_id: 0,
        district_id: 0,
        office_id: 0,
        start_date: '',
        end_date: '',
        limit: 20
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '9%' } },
        { labels: 'globalTrans.division', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'globalTrans.district', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
        { labels: 'priceMonitoring.inspection_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '25%' } },
        { labels: 'specialTaskForce.number_of_agents', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
        { labels: 'specialTaskForce.task_force_activities_area', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '25%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 8, thStyle: { width: '10%' } }
      ],
      districtList: [],
      actions: {
        edit: false,
        toogle: true,
        delete: true
      }
    }
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1 && this.$store.state.Auth.authUser.org_admin === 1) {
      const officeIt = this.$store.state.CommonService.commonObj.officeList.find(item => item.status === 1 && item.value === parseInt(this.$store.state.Auth.authUser.office_detail.office_id))
      this.search.office_id = this.$store.state.Auth.authUser.office_detail.office_id
      this.search.division_id = officeIt.division_id
      this.search.district_id = officeIt.district_id
    }
    if (parseInt(this.$store.state.Auth.activeRoleId) === 1) {
      this.actions.edit = true
    }
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal !== 0) {
          this.districtList = this.getDistrictList(newVal)
        }
      } else {
        this.districtList = []
      }
    }
  },
  computed: {
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'division_name_bn' },
          { key: 'district_name_bn' },
          { key: 'inspection_date' },
          { key: 'number_of_agents' },
          { key: 'task_force_activities_area' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'division_name' },
          { key: 'district_name' },
          { key: 'inspection_date' },
          { key: 'number_of_agents' },
          { key: 'task_force_activities_area' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('specialTaskForce.district_special_task_force_activities') + ' ' + this.$t('globalTrans.entry') : this.$t('specialTaskForce.district_special_task_force_activities') + ' ' + this.$t('globalTrans.modify')
    },
    divisionList: function () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    }
  },
  methods: {
    listUserDisable () {
      if (this.$store.state.Auth.activeRoleId === 1 || this.$store.state.Auth.authUser.org_admin === 2) {
        return false
      }
      return true
    },
    getDistrictList (id) {
      return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === parseInt(id))
    },
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      } else if (type === 2) {
        this.changeStatus(3, bazarMonitoringServiceBaseUrl, specialTaskForceDistrictToggle, item)
      }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(bazarMonitoringServiceBaseUrl, specialTaskForceDistrictList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          const divisionListObj = this.$store.state.CommonService.commonObj.divisionList.find(divisionList => divisionList.value === parseInt(item.division_id))
          const districtObj = this.$store.state.CommonService.commonObj.districtList.find(districtList => districtList.value === parseInt(item.district_id))
          const divisionListData = {}
          const districtData = {}
          if (typeof divisionListObj !== 'undefined') {
            divisionListData.division_name = divisionListObj.text_en
            divisionListData.division_name_bn = divisionListObj.text_bn
          } else {
            divisionListData.division_name = ''
            divisionListData.division_name_bn = ''
          }
          if (typeof districtObj !== 'undefined') {
            districtData.district_name = districtObj.text_en
            districtData.district_name_bn = districtObj.text_bn
          } else {
            districtData.district_name = ''
            districtData.district_name_bn = ''
          }
          return Object.assign({}, item, { serial: index }, divisionListData, districtData)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    remove (item) {
      this.changeStatus(3, bazarMonitoringServiceBaseUrl, specialTaskForceDistrictToggle, item, 'bazar_monitoring_service')
    }
  }
}
</script>
